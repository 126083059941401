@media screen and (min-width: 1440px) {
	.deal-tile-full{min-height:100%;}
	.single-head h1, .single-head h2{font-size: 20px;}
	.single-head p{font-size: 20px;}
}
@media screen and (max-width: 1410px) {
	/*.btn-empty-gold{padding: 0px 17px; font-size: 16px;}*/
	/*.btn-empty-default{padding: 0px 17px; font-size: 16px;}*/
	#exTab1 .nav-tabs a{padding: 14px 15px; font-size: 14px;}
	.d-tools{width: calc(100% - 20px); left: 10px; right: 10px;}
	.deal-price{font-size: 22px;}
	.deal-old-price{font-size: 14px;}
	.deal-tile-title h2, .deal-bought{font-size: 14px !important;}
	.deal-tile-title h2{padding-bottom: 10px;}
	.deal-tile-title{padding: 20px 20px 70px 20px;}
	.clock-real span, #clock span {font-size: 30px !important;}
	.clock-extra span{font-size: 30px;}
	.sec-head-container h3{margin: 0px 20px;}
	.sec-head-container h3{font-size: 16px;}
	.profile-dropdown{margin-left:0px;}

}

@media screen and (max-width: 1280px){
	.sec-head-container h3{font-size: 15px; padding: 0px; margin: 0px 15px;}
	.sec-head-container h2{font-size: 25px;}
	.contact-info{padding:65px 65px;}
	.single-price-old{font-size: 24px;}
	.deal-tile-caption h2, .deal-tile-caption h2 a{font-size: 16px;}
	.gold-btn{padding: 13px 37px;font-size: 13px;}
	.deal-tile-caption{padding: 20px 12px 34px 30px;}
	.deal-voucher{font-size: 16px;}
	.voucher-tlt a{padding: 13px 3px;}
	.single-head-price{font-size: 60px;}
	.btn.btn-buynow{line-height: 52px; font-size: 17px;}
	.profile-form input[type="text"], .profile-form input[type="password"], #areabtn{max-width: 690px;}
	.large-tag img{max-width: 170px;}
	.flex-center-header{margin-left: 20px;}

}
@media screen and (max-width: 1240px){
	.right-header-info{padding-left: 10px;}
	.flex-center-header{margin-left: 10px;}
}
@media screen and (max-width: 1220px){
	.btn-empty-gold, .btn-empty-default{padding: 0px 5px; font-size: 11px;}
	.contant-h-info p a{font-size: 12px;}
	.submit-newsletter{
		padding: 0px 17px !important;
		font-size: 11px !important;
	}
	.h-email{font-size: 11px;}
	.search-box input{font-size: 11px;}
	.search-box input::placeholder{font-size: 11px;}
	.btn-search{line-height: 35px; padding: 0px 10px 3px 10px;}
	.voucher-tlt a{padding: 13px 10px;}
	.btn.btn-soldout{line-height: 55px; padding: 0px 0px;}
	.user-profile-tabs ul li a{padding: 10px 36px; font-size: 15px;}
	.mydeals{margin-top: 30px;}
	.mydealsheader{display: none;}
	.my-deals-tlt{padding: 0px 10px;}
	.nav-item.nav-link span{display: none;}
	.deal-tile-full{min-height: 400px;}

}
@media screen and (max-width: 1120px){
	.header-secondary.dupli{display: none;}
	.deal-tile-caption h2, .deal-tile-caption h2 a{font-size: 16px;}
	.voucher-tlt a{font-size: 13px;}
}
@media screen and (max-width: 1080px){
	.gold-btn{padding: 13px 58px;}
	.single-price-old{font-size: 30px;}
	#logo{padding-left: 0px;}
	.voucher-head-price{font-size: 27px;}
	.voucher-tlt a{padding: 10px 5px;}
	.deal-voucher{font-size: 13px;}
	.header-secondary{display: none;}
	.deals-more-btn a{padding: 21px 80px;}
}
@media screen and (max-width: 780px) {
	.category_name{margin-top: 0px;}
	.c_banner{margin-top: 100px;}
	.affix-help{height: 100px;}
	/*.pgCont.danger{margin-top: 50px;}*/
	.cart-page{padding-top: 30px;}
	div#modalStupids{margin-top: 100px;}
	.modal-header{border-bottom: 0;}
	#selectPackageModal .modal-header{padding-bottom: 0 !important;}
	a#gofoit{margin: 0 auto; text-align: center; display: block;margin-top: 30px;}
	.pay-btn-group label input[type="radio"] ~ i.fa.fa-circle-o{font-size: 1.4em;}
	.pay-btn-group label input[type="radio"]:checked ~ i.fa.fa-dot-circle-o{font-size: 1.4em;}
	.click-to-see, .noclick-to-see{padding-left: 24px !important;}
	.pay-btn-group label.btn span{font-size: 13px;}
	#paymethod label{padding: 0;}
	.iziToast-wrapper.iziToast-wrapper-topCenter{top: 35%;}
	.newsletter-head h3{padding: 0px 15px; font-size: 21px;}
	#modalNewsletter{margin-top: 100px;}
	.newsletter-head h2{font-size: 24px;}
	.m-email{max-width: 400px;}
	#applyModalNewsletter{max-width: 400px;}
	.newsletter_terms{padding: 20px 20px;}
	td.cart-event{vertical-align: middle;padding: 0;padding-left: 5px;}
	#headingTwo button span{display: block;}
	.pay-btn-group label.active span{font-size: 12px;}
	.table.table-striped.table-bordered.table-hover thead tr td{font-size: 14px;vertical-align: middle;}
	/*homepage*/
	.mobile-menu, .mobile-footer{display: block;}
	/*.category-page {margin-top: 70px; }*/
	/*.sections-more{padding: 0px 40px;}*/
	.dp-page-header, .dp-page-menu, .sec-head-container h3, .deal-tile-full, .inside-logos-wrap, .newsletter-icon, #footer-primary ul, .footer-logo img, #footer h3{display: none;}
	.header-secondary{padding-left: 0px;}
	.sec-head-container h2{display: block; text-align: center;}
	.newsletter-form{display: block;}
	.newsletter-form input{max-width: 350px; text-align: center;}
	.submit-newsletter{display: block; max-width: 350px;text-align: center; margin: 0 auto;margin-bottom: 40px; padding: 0px 29px !important;}
	.social-media{justify-content: center;}
	.mobileR a, .mobileL, .mobileR{text-align: center; font-size: 14px!important;}
	.mobile-footer h5{font-size: 22px; color: #252525; font-weight: 600; margin-top: 15px; margin-bottom: 15px;}
	.mobile-footer{text-align: center; margin: 0 auto;}
	.mobile-footer span{display: block; text-align: center; font-size: 18px; color: #ffffff;   line-height: 1.83;}
	#footer a{color: #fff; font-size: 18px;}
	.social-media li a{display: block;  text-align: center;}
	/*mobile menu*/
	.mobile-menu-icons{display: flex; justify-content: space-around; width: 100%; margin-top: 15px;}
	.menu-container{width: 100%; height: 70px; background-color: #252525;/* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.64);*/}
	.mobile-menu{position: fixed; left: 0; right: 0; z-index: 999999999; top: 0;}
	.icon-bar, .icon-bar2, .icon-bar3{background-color:#f5f3ee;}
	.icon-bar, .icon-bar2, .icon-bar3{display: block; width: 28px;height: 2px; margin-top: 6px;}
	.icon-bar.active{transform: rotate(45deg); -webkit-transition: 300ms ease all; -moz-transition: 300ms ease all; -o-transition: 300ms ease all; transition: 300ms ease all;}
	.icon-bar2.active{transform: rotate(-45deg); margin-top: -2px; -webkit-transition: 300ms ease all; -moz-transition: 300ms ease all; -o-transition: 300ms ease all; transition: 300ms ease all;}
	.icon-bar3.active{display: none;}
	.m-menu-logo img{max-width: 150px; margin-left: 45px;}
	/*#mobile-menu-btn{margin-top: 5px;}*/
	.m-menu-logo, .m-menu-search { display: flex ; align-self: center ; }
	/*.m-menu-search span{color: #faf9f5;font-size: 28px;}*/
	#mobile-menu-btn{-webkit-appearance: none; background-color: transparent; border: 0; cursor: pointer ; padding: 0;}
	.deals-more-btn a{display: block; height: 70px; line-height: 65px; width: 100%; padding: 0;}
	.header-secondary{height: 50px; margin-top: 70px;}
	.sec-head-container h2{font-size: 24px; line-height: 50px;}
	.deal-box{margin-bottom: 30px;}
	.deals-mmenu>a{font-size: 20px!important; color: #9e8136!important; font-weight: 700!important;}
	.deals-mvoucher>a{font-size: 20px!important; color: #f5f3ee!important; font-weight: 700!important;}
	.deals-submenu{background-color: #9e8136 !important; min-height: 100vh; padding: 0px 40px !important;}
	.cat-tlt h2{font-size: 24px; font-weight: 600; color: #f5f3ee; text-align: center; padding: 25px 0px;}
	/*#gallery-slider{max-width: 384px;} */
	.sd-head, .mobile-hidden{display: none;}
	.deal-gallery-wrap{margin-top: 70px;}
	.timer-wrap{display: none;}
	.dp-main-content{width: 100%;}
	.mobile-header-box{display:block; padding-bottom: 40px; background-color: #252525; margin-top: 10px;}
	.single-head h1{font-size: 20px; color: #faf9f5; font-weight: 800; padding: 15px 25px;}
	.single-head{margin-top: 10px; padding-top: 20px;}
	.single-head-price{font-size: 40px;}
	.single-price-old{font-size: 20px;}
	.mobile-action-box{padding:0px 25px; display: flex ; justify-content: space-between ;}
	.owl-next, .owl-prev{width: 25px;}
	.owl-prev:before, .owl-next:before{font-size: 30px;}
	#gallery-slider-thumbs{margin-top: 0px;}
	.btn.btn-buynow, .btn.btn-soldout{padding: 0px 0px; font-size: 15px; line-height: 55px; font-weight: 600; max-width: 170px;}
	.mobile-sidebar{display:flex; background-color: #f5f3ee; border: solid 1px #d7d6cf;}
	.clock-extra{border-bottom: 0px; display: flex; justify-content: center; padding-bottom: 10px; margin-right:0px; text-align: center;}
	.clock-real{border-right: 1px solid #252525; padding-right: 10px; font-size: 12px!important;}
    .clock-real span{font-size: 16px !important; margin-right: 7px;}
    .timer-extra img{width: 17px; height: 21px; margin-right: 5px; margin-top: 4px;}
    .clock-extra span{font-size: 15px;}
    .icon-deals img{margin-right: 5px;}
    .clock-extra span{font-size: 12px !important; margin-left: 0px;}
    .icon-deals{display: flex; align-items: center ; justify-content: center ; justify-content: center;}
	#clock_m .clock-real:last-child{border-right:0px; }
	.m-deal-info{border-top: 1px solid #d7d6cf; border-right: 1px solid #d7d6cf; min-height: 52px; display: flex ; align-items: center ; justify-content: center;}
	.bprice-icon{display: none;}
	#nav-tab {
	    flex-wrap: revert;
	    overflow-x: auto;
	    overflow-y: hidden;
	    -ms-overflow-style: -ms-autohiding-scrollbar;
	    -webkit-overflow-scrolling: touch;
	    white-space: nowrap;
	}
	#nav-tab::-webkit-scrollbar {display: none;}
	h2.single-more-head{font-size: 22px;}
	.tab-content h2, .tab-content h1{font-size: 22px;}
	.tab-content ul li, .tab-content p{font-size: 14px;}
	.icon-deals>span{font-size: 12px!important; margin-right: 5px;}
	.footer-logos{display: none;}
	.mfooter-logos{min-height:225px; background: #f5f3ee; border: solid 1px #d7d6cf; display: block;}
	.metromedia-logo{display: block; margin: 0 auto; text-align: center; margin-top: 20px; margin-bottom: 20px;}
	.metromedia-logo span{font-size: 14px; display: block;}
	.metromedia-logo img{max-width: 200px;}
	/*.m-brand-logo img{padding: 10px 6px;}*/
	.mfooter-logos{padding-bottom: 20px;}
/*	.footer-logos ul li:nth-child(1){display: block; margin: 0 auto; text-align: center; margin-top: 20px; margin-bottom: 25px;}
	.footer-logos ul{display: block;}*/
	#exTab1 .nav-tabs a{background-color: transparent; border: 0; margin-bottom:0px; padding: 5px 0px; text-align: center; font-size: 16px; color: #959595 !important; border-bottom: 3px solid #d7d6cf;}
	#exTab1 .nav-tabs a:hover, #exTab1 .nav-tabs a:focus{background-color: transparent; color:#959595 !important;}
	.item .nav-item.nav-link.active{ 
		background-color: transparent !important;
		color: #b09140 !important;
		border-bottom: 3px solid #9e8136!important;
		font-weight: 600 !important; 
		border-left: 0px !important;
		border-right: 0px !important;
		border-top: 0px !important;
	}
	.single-head ul li{color: #faf9f5;}
	.single-head ul li:before{color:#faf9f5; }
	.single-head ul{padding: 0px 25px;}


	/*single voucher*/
	.gift-icon{display: none;}
	.single-head p{color: #faf9f5; padding: 0px 20px;}
	#print-it{text-align: center; display: block; margin: 0 auto; max-width: 100%;}
	.v-mobile-action-box{padding:0px 25px;}
	.v-mobile-action-box .btn.btn-buynow{max-width: 100%;}
	.btn.btn-soldout{max-width: 200px;}
	.main-content-body{ padding:0px 0px;}
	.btn-search{border:0px; font-size: 20px;}

	/*conatact page*/
	.contact-info{padding: 40px 0px;}
	#contact-page h1{line-height: 50px;}
	#map{min-height: 300px;}
	.partner-maps{float: left; width: 50%;}
	.form-group label{font-size: 14px;}
	.partner-maps h4{font-size: 14px;}
	.partner-maps p{font-size: 13px; }
	.contact-social ul{justify-content: flex-start;}
	.form-contact-container{padding: 20px; padding-bottom: 140px;}
	.map-btn{padding: 10px 20px;  font-size: 12px;}
	#register .form-group, #cform .form-group{display: block;}
	.form-tlt{margin-right: 0px; display: block;}
	.register-input, .register-dropdown, .contact-input{width: 100%;}
	/*.contact-body{margin-top: 70px;}*/
	.c-validation{max-width: 100%;}
	#partner-map2, #partner-map1{margin-top: 100px;}
	/*profile page*/
	.user-profile-tabs{margin-top: 50px;}
	.user-profile-tabs ul li a{background-color:transparent; border: 0px !important; padding: 10px 0px; text-align: center;}
	.item li{margin-bottom: 5px;}
	.profile-edit-form .form-group{display: block;}
	.profile-edit-form{padding:0px 15px;}
	.register-input, .register-dropdown, .profile-input{width: 100%;}
	.profile-input, #areabtn{margin-left: 0px;}
	.form-tlt{width: 100%;}
	.profile-form input[type="password"]{max-width: 100%;}
	.mydeal-body{text-align: center;}
	.mydealslist{padding: 0;}
	.search-box {width: 100%;border: 0;border-radius: 0;height: 50px;display: none;margin-right: 0px;border-bottom: 1px solid #fff !important;margin-top: 37px;}
	.search-box input{max-width: 350px; padding: 0px 10px; border-radius: 0px; height: 50px; line-height: 50px;}
	.search-box input::placeholder{color: #fff; font-size: 18px;}
	.search-submit{margin-top: 2px;}

	/*static page*/
	/*.dp-page-group-menu{display: none;}*/
	.dp-page-group-menu{
		width: 100%;
		display: block;
		margin-top: 74px;
	}
	.dp-page-group-menu{flex: 0; padding-top: 0;}
	.dp-main-section{display: block;/* height: inherit;*/}
	/*#generic-page{padding-top: 100px;}*/


	.header-secondary{display: block!important;}
	.single-v-text-head{color: #faf9f5;}
	.single-action-wrap{margin-top: 0px;}

	/*packages*/
	#selectPackageModal{margin-top: 70px;}
	#selectPackageModal .modal-title{
		font-size: 21px;
		margin-top: 10px;
		text-align: left;
	}
	.p-number{font-size: 20px; display: none;}
	.mobile_p_num{display: block;}
	.p-label{font-size: 15px;}
	.p-con{margin-bottom: 5px;}
	.p-info{display: block;}
	.p-div{display: none;}

	.deals-more-btn{margin-top: 30px; margin-bottom:20px;}
	.terms{margin-left: 0px; margin-top: 0px; display: block;}
	.terms input[type="checkbox"]{float: left;}
	.terms p{width: calc(100% - 35px);}
	#search-results{margin-top: 70px; overflow: hidden ;}
	.search-results-list{margin-top: 20px;}
	.card h5 button{font-size: 12px;}
	.card-header{padding: 10px 0px;}
	.cart-title{font-size: 13px; padding: 10px;}
	.tmp-btn{padding-right: 10px;padding-top: 10px;}
	.mobile-pos-change{display: block;float: left;width: 50%;text-align: center;margin-top: 6px;font-size: 14px; font-weight: 600;}
	.mobile-pos-tlt{display: inline-block;width: 50%;text-align: center;float: left;padding: 5px 0px; font-weight: 600;}
	.pull-right.mobile-price-cart{width: 50%;text-align: center;padding: 5px 0px;}
	.pos-change .input-group{width:calc(45% - 22px) !important; justify-content: flex-end;}
	.input-group.bootstrap-touchspin input{max-width: 80px;}
	.cart-page h3{font-size: 17px;}
	.click-to-see, .noclick-to-see{display: block;padding-left: 44px;line-height: 1.4;margin-top: 5px;font-size: 13px;}
	#finish-me{max-width: 220px;}
	/*.pay-btn-group .btn.active{padding: 0;}*/
	.search-header-v .header-secondary{display:block!important;}
	.deal-date, .mydeal-bought, .mydealprice{margin-top: 10px;}
	.category-page{margin-top: 80px;}
	.search-wrap{justify-content: end;}
	.search-wrap h2{font-size: 24px;}
	.form-tlt{margin-right: 0px;}
	.form-tlt{width: 160px;}
	.mobile-banner{background: #9e8136;}
	.m-social span{border: 1px solid #fff;margin-left: 20px;border-radius: 20px;display: block;width: 40px;height: 40px;}
	.m-social span a{line-height: 40px !important;padding: 0 !important;text-align: center !important;font-size: 14px !important;}
	.m-social{display: flex;}
	#accordionDeals{padding: 0px 10px;}
	.table.table-striped.table-bordered.table-hover thead{display: none;}
	.mobile-table-tlt{display: block;text-align: center; padding: 10px; font-weight: 600;}
	.table.table-striped.table-bordered.table-hover td {display: table-row;}
	.card h5 button:after{right: 12px;font-size: 22px;}
	.mobile-user{font-size: 23px;text-align: center;color: #faf9f5; padding: 0px 10px;}
	.dl-trigger.dl-active{height: 20px;}
	.mobile-banner{display: none;}

	/*categories slider*/
	.mobile-category-slider{z-index:999999;display: block;position: fixed;left: 0;right: 0;top: 65px;padding-bottom: 5px;padding-left: 10px;width: 100%;background-color: #353535;box-shadow: 2px 1px 0px 0 rgba(0, 0, 0, 0.44);}
	.swiper-slide a{color: #fff; font-size: 11px; text-align: left; width: initial;}
	.swiper-slide{text-align: left;width: initial !important; max-width: 100% !important;}
	.homepage_deals{margin-top: 100px;}
	.cart-register-btn{margin-top: 40px; margin-bottom: 40px;}
	.menu-tlt{display: block;font-size: 9px;color: #fff;}
	.dl-trigger.dl-active .menu-tlt{display: none;}
	.deal-tile-title{padding: 10px 10px 70px 10px;}
	iframe{max-width: 100%; height: auto;}
	.dp-page-wrapper{margin-top: 23px;}
	#ndeal-menu-banner{display: none;}
	#mdeal-menu-banner{display: block;}
	#mdeal-menu-banner img{margin:0 auto; display: block; max-width: 100%;}
	.card-body img{max-width: 100%; height: auto;}
	.open_menu{border: 0;}
	.hidden-sub-menu{border-top: 0; position: relative;width: 100%; left: 0;}
	.submenu-tlt:after{display: none; content: none;}
	.category_name{padding-top: 20px;}
	.category_name h1{margin-bottom: 20px; font-size: 30px;}
	#pay-summary tbody tr td{width: 100% !important; display: table-cell;}
	#selectPackageModal .modal-dialog .modal-content{margin-top: 40px;}
	.register-title h1{display: none;}
	.dp-page-group-menu h2{margin-top: 100px;}
	.post-share{display: flex;justify-content: space-between; margin-top: 10px; align-items: center;}
	.post-share ul{display: flex;}
	.post-share span{margin-left: 30px;}
	.post-share ul li{
		margin-right: 30px;
		padding-top: 0;
	}
	.post-share ul li a{
		color: #fff;
		line-height: 40px;
		background-color: rgba(158, 129, 54, 0.67);
		font-size: 18px;
		display: block;
		width: 40px;
		border-radius: 20px;
	}
	.blog h1{
		margin-top: 120px;
		margin-bottom: 0;
	}
	.row.blog-cont{margin-top: 0;}
	.post{height: 250px;}
	.post-tlt h2{font-size: 17px;}
	.single_post h1{font-size: 18px;}
	.single_post_wrap{margin-top: 120px;}
	.single_post_tlt>h2{margin-bottom: 0;}
	.wrap_packages{display: flex;}
	#selectPackageModal .modal-body{
		padding-top: 0;
	}
	.mobile_p_num span{
		font-size: 23px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #9e8136;
		padding-right: 7px;
	}
	.p-title{padding-right: 0; padding-left: 16px;}
	.p-title p{font-size: 15px;}
	.p-info{margin-bottom: 0px;}
	.mobile_wrap_pack{
		display: flex;
		justify-content: space-between;
		padding: 0px 25px;
		align-items: center;
	}
	.btn.btn-buynowpack{
		max-width: 170px;
	}
	.pf-price{
		font-size: 28px;
	}
	.dl-menuwrapper li > a:after{
		color: #fff !important;
		right: 20px;
	}
	.mobile_submenu .dl-back{display: block!important;}
	.dl-menuwrapper li.dl-back:after, .dl-menuwrapper li > a:not(:only-child):after{
		line-height: 21px;
	}
	.dl-back>a{
		/*padding: 24px 30px !important;*/
		font-size: 13px !important;
		/*top: 4px !important;*/
	}
	.dl-menuwrapper li.dl-back:after{color: #fff !important; top: 4px !important;}

}
@media screen and (max-width: 375px) {
	.clock-extra span{font-size: 11px !important; margin-left: 2px;}
	.icon-deals img{margin-right: 0px; max-width: 18px; margin-top: 4px;}
	.search-box input{max-width: 300px;}
	.btn.btn-soldout{max-width: 170px;}
	.mobile-action-box{padding: 0px 20px;}

}
@media screen and (max-width: 320px) {
	#selectPackageModal .modal-title{
		font-size: 17px;

	}
	.btn.btn-buynowpack{
		max-width: 117px;
	}
	.pf-price{font-size: 24px;}
	.p-title p{font-size: 13px;}

}