
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800&subset=latin,greek);

body {
    width: 100%;
    height: 100%;

    font-family: 'Open Sans', sans-serif;
    color: #111111;
    background-color: #fff;
    font-weight: 400;
    -webkit-font-smoothing: smooth-antialiased !important;
    line-height: 1.55;
    overflow-x: hidden;
    outline: 0;
}

html {
    width: 100%;
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /*margin: 0 0 35px;*/
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    /*line-height: 1.0;*/
}
h1 { font-size: 1.6em; font-weight: 300; margin-bottom: 10px;}

p {
    margin: 0 0 25px;
    font-size: 16px;
    line-height: 1.5;
}

strong{
    /*font-size: 16px;*/
   /* line-height: 1.56;*/
    font-weight: 600;
}
a {
    color: #111111;
    outline: 0;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

a:hover,
a:focus { text-decoration: none; color: #d0b15f;}

a:hover, a:active, a:focus {
  outline: 0;
  text-decoration: none;
  color: #d0b15f;
  outline-width: 0px;
}
input::-moz-focus-inner { border: 0; }
:focus{ outline-width: 0px !important; }

.btn.focus, .btn:focus, .btn:active {
   outline: none !important;
   box-shadow: none !important;
}



body.noscroll { overflow-y:hidden!important; }

.row.row-grid [class*="col-"] {
    margin-bottom: 30px;
}


.c-validation {
  background: #b09140 !important;
  padding: 3px 6px !important;
  border-radius: 1px !important;
  position: relative;
  display: inline-block;/* !important;*/
  /*box-shadow: 1px 1px 1px #aaaaaa;*/
  margin-top: 12px;
  color: #fff;
  text-align: center;
  max-width: 50%;
  /*margin-bottom: 20px;*/
}
.c-validation.c-hcenter
{
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  max-width: 50%;
}
.c-validation:before{
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #b09140;
  position: absolute;
  top: -10px;
}
.c-validation { display: none; }
.ajax-loading { display: none; position: absolute;right: 30px; top: 0;}
.cajax-loading { display: none; }
.ghost-a{
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
}

.category-header {
  /*height: 200px;*/
  width: 100%;
  /*margin-bottom: 40px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;*/
}
.category-header h1 {
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  margin-bottom: 25px;
  margin-top: 25px;
  display: block;
}

.resp { border: 1px solid #111111; padding: 10px;}
.dp-page-wrapper {  }
.dp-page-header { display: block; }
.main-content-body { padding: 10px 0px; }

/*.header-auth { display: block; text-align: right;  }*/
.header-auth ul { padding-left: 0px; list-style: none; margin-bottom: 12px; margin-top: 15px;}
/*.header-auth ul li { display: inline-block; }*/
.header-auth ul li a{padding: 0px 30px; font-weight: 600; font-size: 13px;}
.header-menu { display: block;/* text-align: right;  */}
.header-menu ul { padding-left: 0px; list-style: none; margin-bottom: 6px;}
.header-menu ul li { display: inline-block; margin-bottom: 0px; }

.header-secondary {
  height: 80px;
	background-color: #151515;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding-left: 240px;
  display: none;
}
.header-secondary.dupli { padding-left: 0px;  }
.header-secondary.dupli h2{font-size: 28px;}
.header-secondary.dupli h3{margin: 0px 10px; font-size: 14px;}

.sec-head-container {
  line-height: 80px;
}
.check-icon{ color: #d0b15f; margin-right: 10px; font-size: 1.2em; }
.sec-head-container h2 {
	display: inline-block;
	/*line-height: 80px;*/
	font-family: 'Open Sans', sans-serif;
	font-size: 34px;
	font-weight: 500;
	text-align: left;
	color: #b09140;
	margin:0px;
  vertical-align: middle;
}
.sec-head-container h3 {
	display: inline-block;
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	color: #ffffff;
	margin: 0px 30px;
  vertical-align: middle;
}
.hs-item { display: inline-block; line-height: 100px; }

/*#bubble-cart {
	z-index: 9999999999;
	position: fixed;
	bottom: 40px;
right: 20px;
	height: 34px;
	width: 34px;
	background: #252525;
	border-radius: 17px;
	text-align: center;
	line-height: 34px;
	color: #9e8136;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.64);
}*/
.shopping{
  z-index: 99999;
  position: fixed;
  bottom: 40px;
  right: 20px;
  height: 38px;
  width: 38px;
  background: #252525;
  text-align: center;
  transform: rotateY(180deg);
  line-height: 38px;
  font-size: 20px;
  color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.64);
}
/*.shopping svg {transform: rotateY(180deg);}*/
.bubble{
    z-index: 9999999999;
    position: fixed;
    bottom: 64px;
    right: 50px;
    height: 24px;
    width: 24px;
    background: #b09140;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    color: #fff;
}
#bubble-cart a, .dpbadge { color:  #fff; font-weight: 400; }

.cart-title { width: calc(100% - 30px);
vertical-align: middle;
display: inline-block; }
.table-responsive { overflow-x: visible; }
.payment-tile { display: inline-block; padding: 0px; text-align: center; margin-right: 15px; margin-left: 15px;}

img.full-width { width: 100%; }
.payment-tile img {  margin: 0 auto;}
.header-sec-link { margin: 0; color: #ffffff; }
/*a.header-sec-link:hover { text-decoration: none; }*/
.dp-main-section { display: flex; min-height: calc(100vh - 546px);  }
.dp-page-menu { flex: 0 0 240px;  background: #9e8136; /*padding-top: 20px;*/}
.dp-page-menu.dupli {  background: #1d1d1b; }
.dp-page-menu ul { padding-left: 0; list-style: none; }
.dp-page-menu ul li { /*padding: 10px 10px 10px 16px;*/ border-bottom: solid 1px #d0b15f;  -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    position: relative; background: #9e8136;}
.dp-page-menu.dupli ul li {  border-bottom: solid 1px  #585858; }
.dp-page-menu ul li a {
   font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 10px 10px 10px 16px;
}
.dp-page-menu ul li a:hover { text-decoration: none; }
.dp-page-menu ul li a span { float: right; text-align: right; }

.dp-page-menu li.active, .dp-page-menu li:hover { background: #d0b15f; }
.dp-page-menu.dupli li.active, .dp-page-menu.dupli li:hover { background: #585858; }
.dp-page-menu h2
{
	font-family: 'Open Sans', sans-serif;
	font-size: 20px;
	font-weight: 600;
	text-align: center;
	color: #ffffff;
  border-bottom: solid 1px #d0b15f;
  padding-bottom: 14px;
  margin-bottom: 0px;
}
.dp-page-menu.dupli h2 { border-bottom: solid 1px #585858; }
.d-tools {
      display: block;
      width: calc(100% - 40px);
      min-height: 36px;
      margin-top: 20px;
      position: absolute;
      bottom: 20px; left:20px; right: 20px;
}

/*.deal-tile-full-image { position: absolute; top: 0; left: 0; right: 0; }*/
.deals-more-btn{text-align: center; margin-top: 100px; margin-bottom: 80px;}
.deals-more-info { border: solid 3px #b09140; font-size: 16px; font-weight: 800; color: #b09140; padding: 20px 50px; margin: 0px auto 50px auto; max-width: 50%; }
.deals-more-btn a{border: solid 3px #b09140; font-size: 16px; font-weight: 800; color: #b09140; padding: 21px 180px;}
.deal-tile { position: relative; height: 100%;background: #252525; }
.tagme-large { position: absolute; top: -9px; right: -9px; }
.tagme-normal { position: absolute; top: -6px; right: -6px; }
.large-tag{position: absolute; top: 0px; right: 0px; }
.large-tag img{max-width: 170px;}
/*.tagme-ex { position: absolute; top: -9px; right: -9px; }*/
.deal-tile-title { padding:10px 10px 70px 10px;}
.deal-tile-title h2{font-size: 16px; padding-bottom: 20px;}
.deal-tile-title h2 a {

	text-align: left;
	color: #ffffff;
}
.deal-price {
	font-size: 24px;
	font-weight: 400;
	/*text-align: left;*/
	color: #b09140;
  text-align: right;
  line-height: 22px;
  /*vertical-align: bottom;*/
  margin-top: -10px;
}
.deal-price.wpad { padding-right: 10px; }
.deal-price.nodis { line-height: 34px; margin-top: 0px; }
.deal-old-price {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: normal;
  text-align: left;
  color: #cdcdcd;
  text-decoration: line-through;
}
.deal-bought {
  font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	text-align: left;
	color: #ffffff;
  line-height: 34px;
  font-weight: 300;
  /*vertical-align: bottom;*/
}
.deal-discount {
  display: block;
  width: 100%;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  /*min-height: 30px;*/
}
.deal-author {
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b09140;
}

.deal-tile-full {
  position: relative; /*min-height: 500px;*/min-height: 100%; width: 100%;
  /*-webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;*/

}
.homepage_deals{margin-top: 20px;}
.category-page .deal-tile-full { margin-bottom: 30px; }
.deal-tile-caption { position: absolute; bottom: 0; left: 0; right: 0; padding: 20px 20px 34px 30px;  background-color: rgba(29,29,27,0.8); /*#1d1d1b*/ display: flex; z-index: 2;}
.dp-flex {  -webkit-flex: 0 1 auto; -ms-flex: 0 1 auto; flex: 0 1 auto; -webkit-align-self: flex-end; -ms-flex-item-align: end; align-self: flex-end; }
.d-title-full { width: 55%; padding-right: 20px; align-self: flex-end; }
.d-tools-ex { width: 18%; }
.d-tools-full { width: 27%; /*padding-right: 20px;*/ }

.deal-tile-caption h2, .deal-tile-caption h2 a{ color: #fff; font-size: 17px; max-width: 600px;}
.deal-tile-caption .deal-author { font-size: 22px; }
.deal-tile-caption .deal-discount { text-align: left; font-size: 17px; }
.deal-tile-caption .deal-bought {  font-size: 19px;  }
.deal-tile-caption .single-head-price { font-size: 46px; font-weight: 700; /*line-height: 70px;*/ }
.gold-btn { border: 1px solid #b09140; color: #fff; background-color: #b09140; padding: 13px 37px; line-height: 50px; font-size: 14px; /*margin-left: 12px;*/}
.gold-btn:hover {color: #fff; background-color: #252525; border: 1px solid transparent;}
.category-page { padding-bottom: 20px; }

a.gold-btn-gen {border: 1px solid #b09140; color: #fff; background-color: #b09140; padding: 13px 78px; line-height: 50px; font-size: 15px; /*margin-left: 12px;*/}
a.gold-btn-gen:hover {color: #fff; background-color: #252525; border: 1px solid transparent; text-decoration: none; }

.voucher-tlt a{padding: 13px 10px;}
.voucher-head-price{  font-size: 28px; color: #b09140; font-weight: 800; margin-bottom: 10px;}
.deal-voucher{display: block; width: 100%; font-size: 17px; font-weight: 400; font-style: normal; font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: left; color: #ffffff;}
.voucher-bought{display: block; width: 100%; font-size: 15px; font-weight: 400; font-style: normal; font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: #ffffff; margin-bottom: 10px;}
.v-tools{text-align: center; display: block; width: calc(100% - 40px); min-height: 36px; margin-top: 0px; position: absolute; bottom: 10px; left: 20px; right: 20px;}
.v-tools a{line-height: 35px; padding: 5px 18px;}


.dp-main-content {flex: 1; padding: 0px 0px; }
.dp-page-footer { display: block; }
/*.sd-head { background-color: #252525; }*/
.single-head h1, .single-head h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
  margin-top: 0px;
}
.single-head { padding-top: 40px; }
.single-head p
{
 	font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  /*color: #faf9f5;*/
  color: #252525;
}
.single-head ul { padding-left: 0px; list-style: none; }
.single-head ul li {
 	font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #252525;/*#faf9f5;*/
}
.single-head ul li:before {
 	content: '+';
 	color: #b09140;
 	font-size: 20px;
 	margin-right: 10px;
}
.single-head-price {
  font-size: 80px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b09140;
}

.more-wrapper { padding-bottom: 50px; }
h2.single-more-head {
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  margin-bottom: 30px;
}

.btn.btn-buynow {
 	line-height: 65px;
  background-color: #b09140;
  width: 100%;
  display: inline-block;
  padding: 0px 40px;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 0;
}
.btn.btn-buynow:hover {  }
.btn.btn-soldout {
  line-height: 65px;
  background-color: #151515;
  width: 100%;
  display: inline-block;
  padding: 0px 40px;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b09140;
  border-radius: 0;
}
.btn.btn-soldout.smg-btn {
  line-height: 40px;
  padding: 0px 0px;
  font-size: 14px;
  font-weight: 600;
}
.btn.btn-buynowpack {
  line-height: 40px;
  background-color: #b09140;
  width: 100%;
  display: inline-block;
  padding: 0px 0px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 0;
  margin-top: 5px;
}

.btn.btn-download {
  line-height: 65px;
  background-color: #866a22;
  width: 100%;
  display: inline-block;
  padding: 0px 40px;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 0;
  margin-top: 10px;
}




.btn-full-gold {
  line-height: 38px;
  background-color: #b09140;
  width: 100%;
  display: inline-block;
  padding: 0px 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  border-radius: 0;
  border: 1px solid #b09140;
}

.btn-empty-gold {
  line-height: 34px;
  background-color: transparent;
  width: 100%;
  display: inline-block;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #b09140;
  border-radius: 0;
  border: 1px solid #b09140;
}
.btn-full-gold-login{
  line-height: 34px;
  background-color: #b09140;
  width: 100%;
  display: inline-block;
  padding: 0px 11px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  border-radius: 0;
  border: 1px solid #b09140;
}
.btn-empty-default {
  line-height: 34px;
  background-color: transparent;
  width: 100%;
  display: inline-block;
  padding: 0px 11px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  border-radius: 0;
  border: 1px solid #656565;
}
.btn-search{
  line-height: 38px;
  background-color: transparent;
  width: 100%;
  min-width: 40px;
  display: inline-block;
  padding: 0px 10px;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: #faf9f5;
  border-radius: 0;
  border: 1px solid #656565;
}


.btn-search.open-search .fa-search:before{
  content: "\f00d";
}


.single-v-text-head {
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: left;
  display: block;
  color: #252525;
}
.single-v-text {
  font-size: 42px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e8136;
  margin-bottom: 15px;
  margin-top: 5px;
}



.single-action-wrap { margin-top:auto; /*margin-bottom: 35px; */padding-top: 40px; }
.single-action-box { /*position: absolute; bottom: 0; left: 0; */ /*align-self: flex-end;*/ /*margin-top: auto;*/ }
.navbar { margin-bottom: 0px; border-radius: 0px; }
#top-menu { display: block; min-height: 123px; background-color: #252525; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.64); padding-bottom: 10px;}
.newsletterReponse { color: #b09140; font-size: 1.2em;}
.mfooter-logos{display: none;}
.footer-logos { min-height: 110px; background: #f5f3ee; border: solid 1px #d7d6cf;}
.footer-logos ul{padding: 0; list-style-type: none; width: 100%; display: flex; justify-content: space-around; margin-bottom: 0; min-height: 110px;}
.footer-logos ul li:nth-child(2), .footer-logos ul li:nth-child(3){margin-top: 15px;}
/*.footer-logos ul li img{padding: 0px 15px;}*/
.footer-logos span{display: block; color: #252525; font-size: 13px;/* padding: 0px 15px;*/}
.footer-logos ul li{display: flex; align-items: center;  padding: 0px 15px;}
.footer-newsletter { min-height: 184px; background: #252525; padding-bottom: 30px;}
.newsletter-icon img{margin: 0 auto; display: block; margin-top: 60px;}
.footer-newsletter h2{font-size: 32px; font-weight: 600; color: #9e8136; text-align: center; margin-top: 20px; margin-bottom: 0px;}
.footer-newsletter p{font-size: 16px; line-height: 1.56; color: #ffffff; text-align: center;}
.newsletter-form{display:flex; justify-content: center ; margin: 0 auto; text-align: center;}
.submit-newsletter{position: relative!important; background-color: #9e8136!important; line-height: 45px!important; font-size: 14px!important; color: #fff!important; cursor: pointer!important; padding: 0px 15px!important; }
.newsletter-form input{width: 100%;max-width: 700px; display: inline-block; background-color: #d2c6a7; border: 0; height: 45px; font-size: 14px; color: #4e4e4e;text-align: right; padding: 0px 20px;}
.newsletter-form input:focus{outline: 0; border:0;}
.newsletter-form input::placeholder{color: #4e4e4e;}
.social-media li a{color: #252525 !important; border: solid 1px #252525; font-size: 18px; width: 44px; height: 44px; display: block; line-height: 44px; text-align: center; border-radius: 22px; }
.social-media li{margin-right: 15px;}
.social-media li a i { line-height: 44px; }

ul.shop-social-media { list-style: none; padding-left: 0px; margin: 20px auto;  align-items: center;
  justify-content: center; text-align: center; flex-direction: row; }
ul.shop-social-media li { align-self: center; margin-right: 10px;}
ul.shop-social-media li a { color: #252525 !important; border: solid 1px #252525; font-size: 18px; width: 44px; height: 44px; display: block; line-height: 44px; text-align: center; border-radius: 22px; }


.inside-logos-wrap {/* margin: 20px 0px;*/ }
#footer { background: #b09140; }
#footer a { color: #fff; }
#footer a:hover{color: #252525;}
#footer h3 { font-weight: 600; margin-bottom: 0px; font-size: 22px; color: #252525;}
#footer-primary { padding: 40px 0px; }
#footer-primary ul { padding: 0; list-style: none; margin-top:0px; flex-wrap: wrap;}
#footer-primary ul li { padding: 5px 0px; color: #fff;}
.footer-logo { padding-left: 30px;  }
.footer-logo img { padding-right: 40px; width: 100%; }
#footer-secondary { background: #a08234; color: #fff; padding: 10px 0px; }
#footer-secondary p { margin: 0; }
.mobileL { text-align: left; }
.mobileR { text-align: right; }
.navbar-custom a { color: #fff; }
/*#logo {padding-top: 14px; padding-left: 10px;}*/
#logo{padding-left: 12px;}
#logo img { max-width: 100%;}
.single-price-old {   font-family: 'Open Sans', sans-serif;
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  /*margin-left: 6px;*/
  text-align: left;
  color: #848484; text-decoration: line-through;
  margin-bottom: -15px;
}

.timer-wrap {  background-color: #f5f3ee; border: solid 1px #d7d6cf; color: #252525;  margin-top: 25px; padding: 10px 20px;}
.timer-wrap h5, .clock-extra h5 {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #252525;
}
#clock {
  font-size: 16px;
  text-align: left;
  color: #252525;
  /*padding-bottom: 34px;*/
  /*border-bottom: solid 1px #b6b4aa;*/
}
.clock-extra { display: block; text-align: left; margin-left: 0px; margin-right: 5px;  padding-bottom: 20px; border-bottom: solid 1px #b6b4aa; padding-top: 15px;}
.clock-extra.lastp { margin-top: 34px; }

#clock span, .clock-extra span {
  font-size: 40px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
  display: inline-block ;
    vertical-align: middle ;
}
.timer-extra{display: flex;}
.timer-extra img{width: 36px; height: 44px; margin-right: 30px;}

.extra-tlt{
    font-size: 16px !important;
    color: #252525 !important;
    font-weight: 400 !important;
    /*display: block;*/
    margin-left: 75px;
    display: inline-block;
    vertical-align: middle;
}
.icon-deals img{margin-right: 22px;}

.clock-real { display: inline-block; text-align: center; margin-left: 5px; margin-right: 5px;  }

#exTab1 .nav-tabs a.active, #exTab1 .nav-tabs a:hover, #exTab1 .nav-tabs a:focus {
  background-color: #f5f3ee;
  color: #b09140 !important;
  border-left: 1px solid transparent;
}
/*#exTab1 .nav>li>a:hover, #exTab1 .nav>li>a:focus,*/
#exTab1 .nav-tabs a {
    background-color: #252525;
    font-size: 14px;
    font-weight: 400;
    padding: 14px 35px;
    border-left: 1px solid #959595;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #959595 !important;

}
#exTab1 .nav-tabs a { border-radius: 0px; color: #fff; border: 0; border-left: 1px solid #959595;}
#exTab1 {  margin: 40px 0px 50px 0px; }
#exTab1 .tab-content {
	color: #252525;
  background-color: #f5f3ee;
  border-bottom: solid 1px #d7d6cf;
  border-left: solid 1px #d7d6cf;
  border-right: solid 1px #d7d6cf;
  padding: 25px 20px;
  min-height: 300px;
}
#exTab1 .tab-content h2 { color: #b09140; }

.deal-gallery-wrap { margin-top: 25px; }
.deal-gallery-wrap {}

.row.is-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.row.is-flex > [class*='col-'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.btn {
    border-radius: 0;
    font-weight: 600;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.btn-default {
    border: 1px solid #b09140;
    color: #fff;
    background-color: #b09140;
    padding: 0px 20px;
    line-height: 32px;
    font-size: 14px;

}

.btn-default:hover,
.btn-default:focus {
    border: 1px solid transparent;
    outline: 0;
    color: #b09140;
    background-color: #252525;
}

#generic-page { padding-top: 50px; }
#generic-page h1.generic-page-title {
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  margin-bottom: 40px;
}
.generic-page-body{padding-bottom: 100px;}
.generic-page-body p{color: #252525; line-height: normal; margin: 0 0 15px;}
.generic-page-body p a{color: #9e8136;}
.generic-page-body h2 { color: #9e8136; font-size: 16px; font-weight: 700; margin: 35px 0px 15px ;}
.dp-page-group-menu { flex: 0 0 240px;  background: #f5f3ee; padding-top: 20px; }
.dp-page-group-menu ul { padding-left: 0; list-style: none; }
.dp-page-group-menu ul li { padding: 10px 10px 10px 16px; border-bottom: solid 1px #d7d6cf; }
.dp-page-group-menu ul li a {
   font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
  text-decoration: none;
}
.dp-page-group-menu ul li.active {  background-color: #eeeadd; }
.dp-page-group-menu ul li.active a { color: #9e8136;   }
.dp-page-group-menu ul li a:hover { text-decoration: none; color: #9e8136; background-color: #eeeadd; }
.dp-page-group-menu ul li a span { float: right; text-align: right; }
.dp-page-group-menu h2
{
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: #252525;
  /*border-bottom: solid 1px #d7d6cf;*/
  padding-bottom: 14px;
  margin-bottom: 0px;
  margin-top: 70px;
  padding-left: 40px;
}
 /* background-color: #f5f3ee;
  border: solid 1px #d7d6cf;*/
#selectPackageModal .modal-dialog { max-width: 1052px; }
#selectPackageModal .modal-dialog .modal-body { padding-bottom: 50px; }
#selectPackageModal .modal-dialog .modal-content {

  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: solid 1px #979797;
  border-radius: 0;
  outline: 0
}
#selectPackageModal .modal-title {
  font-size: 36px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  width: 100%;
  margin-top: 40px;
}
.p-row { border-bottom: solid 1px #d7d6cf; padding-top: 25px;
  padding-bottom: 15px; }
.p-number { font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e8136; }
.p-label {
font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}
.o-price {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e8136;

}
.p-price {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e8136;

}
.p-info {
 /* padding-top: 25px;*/
  margin-bottom: 15px;
  display: flex;
}
.p-div { padding-left: 20px; padding-right: 20px; }
.p-title {
font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
  padding-right: 50px;
  /*padding-top: 15px;
  padding-bottom: 15px;*/
}
.pf-price
{
   font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b09140;
}



/*register-page*/
#register-page{/* border-top: 1px solid #cfcfcf;*/ margin-top: 15px;}
.register-box{border: solid 1px #cfcfcf; margin-bottom: 100px; /* background-color: #f6f6f6;*/}
.register-title h1{font-size: 50px; font-weight: 900; text-align: center; color: #4d4d4d; margin-top: 20px; margin-bottom: 20px;}
/*.registerForm, .loginForm{padding: 0px 50px;}*/
/*.register-form{padding-top: 120px; border-left: 1px solid #c7c7c7; background-color: #fff; height: 100%;}*/
.register-form{margin-top: 65px;}
.register-form form{padding: 0px 40px;}
.form-group label{font-size: 16px; font-weight: 600; color: #252525; display: inline-block; text-align:left;}
.registerForm input, .loginForm input, .contact-input input{background-color: #f5f3ee; border: solid 1px #d7d6cf; height: 60px; border-radius: 0; line-height: 30px;}
.contact-input textarea{background-color: #f5f3ee; width: 100%; max-width: 685px; border: solid 1px #d7d6cf; height: 140px; border-radius: 0;}
.contact-input input{max-width: 685px;}
.register-select label{display:flex; margin-right: 40px;}
.form-group input:focus, .contact-input textarea:focus{box-shadow: 0 0 0 .1rem rgba(176, 145, 64, 0.5); border-color: rgba(176, 145, 64, 0.52); background-color: #f5f3ee; outline: 0;}
#r-male, #r-female{-webkit-appearance: none; width: 24px; height: 24px; background-color: #ffffff; border: solid 1px #d7d6cf; line-height: 1; margin-right: 7px;}
.register-input span{font-size: 14px; color: #505050;}
#r-male:checked:after, #r-female:checked:after {
    content: "\f00c";
    font-family: FontAwesome;
    text-align: center;
    line-height: 24px;
    display: block;
    color: #d0b15f;
    font-weight: 100;
    font-size: 14px;
}
.register-input select{-webkit-appearance: none; height: 60px; background-color: #f5f3ee; border: solid 1px #d7d6cf; text-align: center; width: 100%;}
.register-input select:after{content: "\f107"; font-family: FontAwesome;}
#register .form-group, .register-select, #cform .form-group{display: flex;}
.form-tlt{width: 160px; /*margin-right: 60px;*/ display: flex; align-self: center;}
.terms input[type="checkbox"]{-webkit-appearance: none; width: 24px;height: 24px;background-color: #ffffff; border: solid 1px #d7d6cf;line-height: 1; margin-right: 7px;}
.terms input[type="checkbox"]:checked:after{
     content: "\f00c";
    font-family: FontAwesome;
    text-align: center;
    line-height: 24px;
    display: block;
    color: #d0b15f;
    font-weight: 100;
    font-size: 14px;
}
.terms p{display: inline-block; margin: 0;  font-size: 14px; color: #505050; font-weight: 800;}
.terms{/*display: flex;
align-items: center;
vertical-align: middle;
margin-top: 30px;
text-align: center;*/
margin-top: 30px;
display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.terms p a{color: #9e8136; font-weight: 800;}

.register-input, .contact-input{width: 100%;}
.register-dropdown{width: 100%;}
.registerForm input::placeholder{font-size: 14px; color: #b09140; line-height: 35px;}
.profile-dropdown{width: 100%; margin-left: 24px;}
/*#rconfassword{background-color: #fff;}*/
.register-btn a, .login-btn a {background-color: #b09140; font-size: 14px; font-weight: 900; color: #ffffff; padding: 10px 56px; border-radius: 0;  }
.register-btn a:hover, .login-btn a:hover{background-color: #659f8c;}
#dropdownarea{
    height: 60px;
    background-color: #f5f3ee;
    border: solid 1px #d7d6cf;
    width: 100%;
    -webkit-appearance: none;
    color: #505050;
    font-size: 16px;
    display: block;
}
.dropdown-toggle::after{border-top: 0;}
#dropdownarea::after{content: "\f107"; font-family: FontAwesome; color: #787878; font-size: 18px; text-align: right; right: 30px; position: absolute;}
.area-db{margin-bottom: 60px; margin-top: 30px;}
.register-btn {text-align: center; padding: 60px 0px 150px;}
.login-btn {text-align: right; padding: 38px 0px 79px;}
.forgot-link a{color: #b09140; text-align: right; margin-right: 20px; display: block; font-size: 13px; padding-top: 10px;font-weight: 900;}
.forgot-link a:hover{color: #e10d2c;}
.register-txt p{margin-bottom: 0; padding: 0px 50px 50px; font-size: 14px; color: #b09140;}
.register-txt p a{font-weight: 600; color: #b09140;}
.register-txt p a:hover { color: #fff; }
.register-logo-side { background: #252525; height: 100%; padding-top: 100px; }
.register-logo-side p{font-size: 14px; color: #b09140; text-align: center; padding: 20px; }
.register-logo-side p a{color: #b09140; font-weight: 600;display: block ;}
.register-logo-side p a:hover{color: #c7c7c7;}
.register-logo-side img{margin: 0 auto; display: block; width: 70%; margin-top: 0px; margin-bottom: 0px;}

/*border:0; background-color: #b09140; font-size: 14px; font-weight: 900; color: #ffffff; padding: 10px 56px; border-radius: 0;*/
.form-btn { cursor:pointer;
line-height: 40px;
border: 1px solid #b09140;
  background-color: #b09140;
  display: inline-block;
  padding: 0px 56px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 0;
  margin-top: 5px;
}
.form-btn:hover {background-color: #252525; border: 1px solid #252525;}
.form-btn-reg {text-align: right; padding: 38px 0px 30px;}
.form-btn {text-align: right; /*padding: 38px 0px 79px;*/}
/*user profile*/

#user-profile-wrap { /*border-top: 1px solid #cfcfcf;*/ padding-top: 40px; padding-bottom: 110px;}
.user-profile-title h1{font-size: 50px; font-weight: 600; text-align: center; color: #4d4d4d; margin-top: 50px; margin-bottom: 80px;  }
.profile-edit-form h3{font-size: 22px; font-weight: 600; color: #4d4d4d;}
#user-profile-wrap .user-img { height: 100%; background: #252525; padding-top: 70px;}
#user-profile-wrap .user-img img { padding: 20px; width: 70%; }
#user-profile-wrap .user-img label { color: #fff; }
#profile{padding-top: 50px;}
.user-img img{margin:0 auto; display: block; border-radius: 125px;}
.user-name{text-align:center;}
.edit-pen{display: block; background-color: #b09140; width: 36px; height: 36px; line-height: 36px; color: #fff; border-radius: 18px; margin: 0 auto; margin-top: -20px; z-index: 2; position: relative;}
.user-name span{font-size: 30px; font-weight: 600; text-align: center; color: #fff; display: block; margin-top: 20px; margin-bottom: 10px;  }
.change-pass{font-size: 14px; color: #6b6b6b; }
#user-profile-content{margin-top: -1px; background-color: #f5f3ee; border: solid 1px #d7d6cf}
.profile-form .form-group{position: relative;}
.profile-form input[type="text"], .profile-form input[type="password"]{
    height: 60px;
    margin: 0;
    padding-left: 18px;
    font-size: 16px;
    width: 100%;
    color: #505050;
    font-weight: 500;
    max-width: 820px;
    border-radius: 0px;
    background-color: #fff;
    border: solid 1px #d7d6cf;
}
.profile-edit-form{padding: 0px 40px;}
#areabtn{
    height: 60px;
    background-color: #fff;
    border: solid 1px #d7d6cf;
    width: 100%;
    -webkit-appearance: none;
    color: #505050;
    font-size: 16px;
    display: block;
    max-width: 820px;
    position: relative;
    /*margin-left: 7px;*/
}
#areabtn::after{content: "\f107"; font-family: FontAwesome; color: #787878; font-size: 18px; text-align: right; right: 30px; position: absolute;}

 .profile-form input[type="password"]{
/*    text-align: center;
    margin-left: 40px; margin-right: 40px;
    max-width: 300px;*/
}
.profile-input{width: calc(100% - 110px); margin-left: 7px;}
.profile-input span{display: block;font-size: 12px; color: #505050;}
.input-icon{position: absolute; top: 11px; left: 14px; max-height: 15px;}
#pfacebook, #ptwitter, #pinstagram{padding-left: 48px;}
.profile-form h4{font-size: 22px; font-weight: 900; color: #4d4d4d; margin-top: 40px; }
.submit-btn a{background-color: #b09140; color: #fff; font-size: 14px; font-weight: 900; padding: 11px 56px; border-radius: 19px;}
.submit-btn{text-align:center; margin-top: 70px; padding-bottom:40px;}
.profile-edit-form .form-group{display: flex;}
/*#user-profile-tabs li{
   background-color: #252525;
  border: solid 1px #656565 !important;
  height: 50px;
}*/
/*#user-profile-tabs li a{
    font-size: 16px;
    font-weight: 900;
    color: #bababa;
    padding: 8px 60px;
    border: solid 1px #cfcfcf;
    border-bottom: 0px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}*/
/*#user-profile-tabs  li a.active{background-color: #fff !important; font-weight: 900; color: #4d4d4d !important; height: 50px; margin-top: -9px; padding: 12px 60px;}*/
.user-profile-tabs ul li a{
    background-color: #252525;
    color: #959595;
    border: solid 1px #656565 !important;
    border-top-left-radius:0 !important;
    border-top-right-radius:0 !important;
    border-right: 0px !important;
    padding: 10px 50px;
    font-size: 16px;
    font-weight: 600;
}
.nav-tabs{border-bottom: 0;}
.user-profile-tabs ul li a.nav-link.active { background-color: #f5f3ee; border: solid 1px #d7d6cf !important; border-bottom: 1px solid #f5f3ee !important; color: #b09140; }
/*interests tab*/
.intrested_wrap{padding: 50px 70px;}
.intrested_wrap h5{font-size: 22px; font-weight: 900; color: #4d4d4d; margin-bottom: 20px;}
.intrested_wrap h4{font-size: 18px; line-height: 1.56; color: #6b6b6b; margin-bottom: 40px;}
.multi-select-options{padding:0;}
.multi-select-options label span{font-size: 14px; color: #6b6b6b;  margin-left: 6px;}
.interests-btn a{background-color: #b09140; color: #fff; font-size: 14px; font-weight: 900; padding: 11px 56px; border-radius: 19px;}
.interests-btn{text-align:right; margin-right: 80px; margin-top: 80px; padding-bottom:120px;}
.multi-select-options li{margin-left: 40px;}




.product-item{
    position: relative;
    width: 100%;
    height: auto;
}
#gallery-slider{/*max-width:800px; height:auto;*/ position: relative;}
#gallery-slider-thumbs{margin-top: 20px;}
.owl-prev{position: absolute; top: 0; left: 0; width: 55px; background-color: rgba(37, 37, 37, 0.3)!important; border-radius: 0 !important; padding: 0 !important; margin: 0 !important; height: 100%; display: flex !important; align-items: center; justify-content: center}
.owl-prev:before{font-family: FontAwesome; content: " \f104"; color: #fff; font-size: 40px; line-height: 1;}
.owl-next{position: absolute; top: 0; right: 0; width: 55px; background-color: rgba(37, 37, 37, 0.3)!important; border-radius: 0 !important; padding: 0 !important; margin: 0 !important; height: 100%; display: flex !important; align-items: center; justify-content: center}
.owl-next:before{font-family: FontAwesome; content: " \f105"; color: #fff; font-size: 40px; line-height: 1;}


/*search box*/
.search-box{height: 38px;background-color: #151515;margin-top: 16px;border: 1px solid #b09140;border-radius: 19px; max-width: 550px;}
/*.search-box.open-search-box{display: block;}*/
.search-box input{width: 100%;max-width: 800px;height: 36px;padding: 0px 20px; font-size:14px;background-color: transparent;color: #fff;line-height: 36px;border-radius: 19px;border: 0;}
.search-box input::placeholder{color: #fff; font-size: 14px;}
.search-box input:focus{outline: 0;}
.search-box form{display: flex; justify-content: center; margin-top: 2px;}
.search-submit{border: 1px solid #b09140; background-color: #b09140; border-radius: 19px; cursor: pointer; display: flex; height: 38px; align-items: center; justify-content: center; width: 38px;margin-top: -3px; margin-right: 0px; font-size: 14px;}
.close-search{display: none; line-height: 36px; background-color: transparent; width: 100%; padding: 1px 12px; font-size: 15px; font-weight: 400; text-align: center; color: #b09140; border-radius: 0; border: 1px solid #b09140;}
.r-dropdown{width: 100%;}


.mobile-menu, .mobile-footer{display: none;}

/*contactpage*/
#contact-page h1{
  font-size: 26px;
  color: #ffffff;
  font-weight: 700;
  line-height: 80px;
}
#contact-page .header-secondary{padding-left: 0px;}
.contact-body{  background-color: #f5f3ee;}
#map{height: 100%;}
.contact-info{padding: 65px 80px;}
.contact-info h2{font-size: 20px; font-weight: 700; color: #9e8136; margin-bottom: 20px}
.form-contact-container{background-color: #fff; padding: 60px 30px 120px 80px;}
.form-contact-container h3{font-size: 20px; color: #9e8136; font-weight: 700;}
.form-contact-container h3 span{display: block; font-weight: 500; font-size: 18px;}
.partner-maps h4{font-size: 16px; color: #252525; font-weight: 700; margin-bottom: 0px; margin-top: 20px;}
.partner-maps p{margin: 0; padding: 0px 0px 30px 0px;}
.partner-maps{margin-bottom: 40px;}
.contactbtn{text-align: center; margin-top: 70px;}
.contactSubmit{background-color: #9e8136;color: #ffffff; padding: 14px 87px; cursor: pointer;}
.map-btn{border: solid 1px #9e8136; font-size: 14px; font-weight: 600; color: #9e8136; padding: 10px 40px; cursor: pointer;}
.contact-social ul{list-style-type: none; padding: 0;}
.map-btn:hover{background-color: #9e8136; color: #fff;}
.modal-dialog{max-width: 800px;}
#laserline-map, #auteco-map{height: 500px;}
.dl-menu.dl-menuopen.dl-subview .mobileprofile{display: flex !important; opacity: 1; background-color: #151515 !important;}
.mobileprofile ul{height: 100px; background-color: #252525 !important; display: flex; justify-content:space-around; align-self: center; padding: 30px 0px !important; width: 100%;}
.mobileprofile ul li {display: block !important;}
.m-empty-default{border: 1px solid #656565; padding: 14px 55px !important; font-size: 14px !important; color: #ffffff !important; font-weight: 600 !important;}
.btn-m-gold{background-color: #9e8136; font-size: 14px !important; font-weight: 600 !important; color: #ffffff !important; padding: 15px 63px !important;}
.dl-back{display: none!important;}
#partner-map, #partner-map2, #partner-map3 {height: 400px;}
h4.shop-head { margin-top: 20px; text-align: center; }
.partner-logo { max-width: 300px; margin: 20px auto;}
.partner-logo img { width: 100%; }
.active-cat-deal{background-color: #D0B15F;}
.active-cat-voucher{background-color: #585858;}
.tab-content hr{border-color: #151515; border-top: 2px solid #151515; }
.tab-content a {color:#d0b15f!important;}
.tab-content a:hover{text-decoration: underline;}
.tab-content a i{color: #252525 !important;}
.tab-content a.gold-btn-gen {color:#fff !important;}
.tab-content  a.gold-btn-gen:hover{text-decoration: none;}
/*mydeals porfile page*/
.mydealsheader h2{font-size: 18px; color: #9e8136; font-weight: 800; text-align: center;}
.mydealsheader{padding: 30px;}
.mydeal-body{min-height: 110px; background-color: #ffffff; border: solid 1px #d7d6cf; margin-bottom: 10px;}
.mydealslist{padding: 0px 30px;}
.mydeals-img{display: flex; justify-content: center; margin-top: 15px; padding: 0px 5px;}
.my-deals-tlt h3 a{  color: #252525; font-size: 14px; }
.my-deals-tlt h3{font-size: 14px; margin-top: 20px;}
.deal-date, .mydeal-bought, .mydealprice{display: block; text-align: center; margin-top: 40px; font-size: 14px; color: #252525; font-weight: 700;}


.custom-checkbox-uni a:hover { color: #b09140; }
.custom-checkbox-uni > [type="checkbox"],
    .custom-checkbox-uni > a#toggleterms{
        margin-bottom:0px !important;
        margin-top: 10px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .custom-checkbox-uni > [type="checkbox"]:not(:checked),
    .custom-checkbox-uni > [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
    }
    .custom-checkbox-uni > [type="checkbox"]:not(:checked) + a#toggleterms,
    .custom-checkbox-uni > [type="checkbox"]:checked + a#toggleterms {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
    }
    .custom-checkbox-uni > [type="checkbox"]:not(:checked) + a#toggleterms:before,
    .custom-checkbox-uni > [type="checkbox"]:checked + a#toggleterms:before {
        content: '';
        position: absolute;
        left:0;
        top: 50%;
        margin-top:-12px;
        width: 24px;
        height: 24px;
        border: 1px solid #b09140;
        background: #fff;
        border-radius: 0px;
    }
    .custom-checkbox-uni > [type="checkbox"]:not(:checked) + a#toggleterms:after,
    .custom-checkbox-uni > [type="checkbox"]:checked + a#toggleterms:after {
       /*font: normal normal normal 12px/1 'Glyphicons Halflings';*/
        font-family: 'FontAwesome';
        font-size: 18px;
        content: '\f00c';
        position: absolute;
        top: 50%;
        margin-top:-12px;
        left: 3px;
        color: #b09140;
        xtransition: all .2s;
    }

    .custom-checkbox-uni > [type="checkbox"]:not(:checked) + a#toggleterms:after {
        opacity: 0;
        transform: scale(0);
    }
    .custom-checkbox-uni > [type="checkbox"]:checked + a#toggleterms:after {
        opacity: 1;
        transform: scale(1);
    }

    .custom-checkbox-uni > [type="checkbox"][data-indeterminate] + a#toggleterms:after,
    .custom-checkbox-uni > [type="checkbox"][data-indeterminate] + a#toggleterms:after {
        content: '\2212';
        left: 2px;
        opacity: 1;
        transform: scale(1);
    }

    .custom-checkbox-uni > [type="checkbox"]:disabled:not(:checked) + a#toggleterms:before,
    .custom-checkbox-uni > [type="checkbox"]:disabled:checked + a#toggleterms:before {
        box-shadow: none;
        background-color: #eeeeee;
        border-color: #eeeeee;
        cursor: not-allowed;
        opacity: 1;
        color: #dadada;
    }
    .custom-checkbox-uni > [type="checkbox"]:disabled:checked + a#toggleterms:after {
      color: #dadada; cursor: not-allowed;
    }
    .custom-checkbox-uni > [type="checkbox"]:disabled + a#toggleterms {
      color: #aaa; cursor: not-allowed;
    }
    .custom-checkbox-uni > [type="checkbox"]:checked:focus + a#toggleterms:before,
    .custom-checkbox-uni > [type="checkbox"]:not(:checked):focus + a#toggleterms:before {
        border: 1px solid #f53000;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
    .custom-checkbox-uni > a#toggleterms:hover:before {
        border: 1px solid #b09140 !important;
    }
    .custom-checkbox-uni > [type="checkbox"]:disabled:not(:checked) + a#toggleterms:hover:before,
    .custom-checkbox-uni > [type="checkbox"]:disabled:checked + a#toggleterms:hover:before{
        border: 1px solid #b09140 !important;
    }


  .custom-checkbox > [type="checkbox"],
    .custom-checkbox > label{
        margin-bottom:0px !important;
        margin-top: 10px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .custom-checkbox > [type="checkbox"]:not(:checked),
    .custom-checkbox > [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
    }
    .custom-checkbox > [type="checkbox"]:not(:checked) + label,
    .custom-checkbox > [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
    }
    .custom-checkbox > [type="checkbox"]:not(:checked) + label:before,
    .custom-checkbox > [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left:0;
        top: 50%;
        margin-top:-12px;
        width: 24px;
        height: 24px;
        border: 1px solid #b09140;
        background: #fff;
        border-radius: 0px;
    }
    .custom-checkbox > [type="checkbox"]:not(:checked) + label:after,
    .custom-checkbox > [type="checkbox"]:checked + label:after {

        font-family: 'FontAwesome';
        font-size: 18px;
        content: '\f00c';
        position: absolute;
        top: 50%;
        margin-top:-12px;
        left: 3px;
        color: #b09140;
        xtransition: all .2s;
    }

    .custom-checkbox > [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }
    .custom-checkbox > [type="checkbox"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    .custom-checkbox > [type="checkbox"][data-indeterminate] + label:after,
    .custom-checkbox > [type="checkbox"][data-indeterminate] + label:after {
        content: '\2212';
        left: 2px;
        opacity: 1;
        transform: scale(1);
    }

    .custom-checkbox > [type="checkbox"]:disabled:not(:checked) + label:before,
    .custom-checkbox > [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        background-color: #eeeeee;
        border-color: #eeeeee;
        cursor: not-allowed;
        opacity: 1;
        color: #dadada;
    }
    .custom-checkbox > [type="checkbox"]:disabled:checked + label:after {
      color: #dadada; cursor: not-allowed;
    }
    .custom-checkbox > [type="checkbox"]:disabled + label {
      color: #aaa; cursor: not-allowed;
    }
    .custom-checkbox > [type="checkbox"]:checked:focus + label:before,
    .custom-checkbox > [type="checkbox"]:not(:checked):focus + label:before {
        border: 1px solid #b09140;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
    .custom-checkbox > label:hover:before {
        border: 1px solid #b09140 !important;
    }
    .custom-checkbox > [type="checkbox"]:disabled:not(:checked) + label:hover:before,
    .custom-checkbox > [type="checkbox"]:disabled:checked + label:hover:before{
        border: 1px solid #b09140 !important;
    }


.page-header { padding: 40px 0px; }
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up { border-radius: 0; border-top-right-radius: 0px; }

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down { border-radius: 0; border-bottom-right-radius: 0px; }

.bootstrap-touchspin .input-group-btn-vertical>.btn {
  display: block;
  float: none;
  max-width: 100%;
  min-width: 22px;
  height: 20px;
  padding: 0px 0px;
  text-align: center;
  line-height: 18px;
  margin-left: -1px;
  position: relative;
}

.btn-spin {
  color: #fff;
  background-color: #b09140;
  padding: 0px 2px;
  line-height: 16px;
  width: 18px;
  text-align: center;
  /*border: 0;*/
}
.btn-spin:hover { color: #fff; background-color: #252525; }
.cart_quantity_input{
  text-align: center;
  padding: 0px 0px;
  height: 32px;
}

.cart_quantity_button{
  display: flex; align-items: center;
}

.pos-change .input-group {
  width: calc(100% - 22px) !important;
}
.pos-change  .form-control { width: calc(100% - 22px); border-radius: 0px; text-align: center; }

td.pos-change { min-width: 85px; vertical-align: middle;}
td.cart-event { vertical-align: middle; }
td.remove-prod { vertical-align: middle;  text-align: center; }
.tmp-btn { display: inline-block; float: right; }
.tmp-btn a, .tmp-btn a:visited, .tmp-btn a:focus { color: #b09140; }
.tmp-btn a:hover { color: #e51515; }
.pay-btn-group { text-align: left; }
.pay-btn-group label.btn { /*display: block; clear: both;*/ text-align: left; }
.pay-btn-group label.btn span {
  font-size: 1.0em;
  vertical-align: middle;
}

.pay-btn-group label input[type="radio"] ~ i.fa.fa-circle-o{
    color: #c8c8c8;    display: inline; vertical-align: middle;
}
.pay-btn-group label input[type="radio"] ~ i.fa.fa-dot-circle-o{
    display: none;
}
.pay-btn-group label input[type="radio"]:checked ~ i.fa.fa-circle-o{
    display: none;
}
.pay-btn-group label input[type="radio"]:checked ~ i.fa.fa-dot-circle-o{
    color: #b09140;    display: inline; vertical-align: middle;
}
.pay-btn-group label:hover input[type="radio"] ~ i.fa {
color: #b09140;
}

.pay-btn-group label:hover input[type="radio"][disabled] ~ i.fa {
color: #c8c8c8;
cursor: not-allowed;
}



.pay-btn-group label.active{
    color: #b09140;
}

.pay-btn-group label.active span{
    color: #b09140;
}

.pay-btn-group label.btn.disabled span{
  color: #c8c8c8; opacity: opacity: .65; cursor: not-allowed;
}

.pay-btn-group div[data-toggle="buttons"] label {
    display: inline-block;
    padding: 6px 0px;
    margin-bottom: 0;
    margin-right: 20px;
    font-size: 0.8em;
    font-weight: normal;
    line-height: 2em;
    text-align: left;
    white-space: nowrap;
    vertical-align: top;
    cursor: pointer;
    background-color: none;
    border: 0px solid
    #c8c8c8;
    border-radius: 0px;
    color: #c8c8c8;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.pay-btn-group div[data-toggle="buttons"] label:hover {
  color: #b09140;
}

.pay-btn-group div[data-toggle="buttons"] label:active, .pay-btn-group div[data-toggle="buttons"] label.active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*.pay-btn-group [data-toggle="buttons"]>.btn input[type="radio"], [data-toggle="buttons"]>.pay-btn-group>.btn input[type="radio"], [data-toggle="buttons"]>.btn input[type="checkbox"], [data-toggle="buttons"]>.pay-btn-group>.btn input[type="checkbox"] */

.pay-btn-group>.btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

#pay-banktransfer, #pay-handbyhand, #pay-inhouse { display: none; }
.mobile-header-box, .mobile-sidebar{display: none;}

.gift-icon img, .bprice-icon img{display: block; margin: 0 auto; margin-top: 40px;}
.mobileR, .mobileL{font-size: 14px;}
#nav-tab{display: flex;}
.thankYou {
    text-align: center;
    padding-top: 100px;
    font-size: 20px;
    color: #9e8136;
    font-weight: 700;
}
.close-btn{padding: 7px 15px 0px;}



/*.banner-wrap{position: relative;}*/
#deal-menu-benner{     padding: 0.5ex;
    width: 200px;
    /* height:300px; */
    background-color: #333;
    color: #fff;
    font-size: 2em;
    border-radius: 0.5ex;
    float:left;
    height: 300px;
  }
.stick {
    position: fixed;
    top: 10px;
    z-index: 10;
    margin-left: 10px;

}
#deal-menu-banner img{
  display: block;
  margin:0 auto;

}
.clear{clear:both;}


.search-results-list{margin-top:40px;margin-bottom: 40px;}
.search-term{height: 60px; background-color: #151515;}

.search-wrap h2{
  line-height: 60px;
  color: #fff;
  font-size: 28px;
  margin-left: 10px;
}
.search-wrap{display: flex; justify-content: center;}
.search-wrap .search_form{align-items: center; align-self: center; margin-left: 20px; line-height: 60px;}
.search-wrap input[type="text"]{text-align: left !important; background-color: transparent; border: 0px; color: #fff; font-size: 24px;}
.search-wrap input[type="text"]::placeholder{color: #fff;}
.search-wrap input[type="text"]:focus{outline: 0; border:0; box-shadow: none;}
.no-results{padding: 40px 0px;}
.card h5 button{color: #b09140; width: 100%; text-align: left ;}
.card{margin-bottom: 15px;}
.card h5 button:hover{text-decoration: none; color: #111111;}
.card-body p{color: #252525; font-size: 16px;}
.card-body p a{color: #9e8136; font-weight: bold;}
.card-body ul li a{color: #9e8136;}
.card-body a{color: #9e8136; font-weight: bold;}
.card-body a:hover{text-decoration: underline !important;}
.search-header-d .header-secondary{margin-top: 0px;}

/*search pagination*/
.pagination{text-align: center; display: flex; justify-content: center;}
.pagination li{padding: 0px 10px; font-size: 18px;}
.pagination li.active span{ color: #9e8136; }
#footer-payments-logos  {margin: 20px 0px;}

.mobile-banner img{display: block; margin: 0 auto; padding: 10px 0px;}


.iziToast-message{line-height: 1.4 !important; font-size: 18px !important;}
.crb{padding: 10px 40px !important; background-color:#9e8136!important; color: #fff !important; margin-top: 20px !important;}
.iziToast-buttons{float: none !important; text-align: center !important;}

/*.ekdromi-tlt.deal-tile .deal-tile-title{padding: 0px 10px;}*/
.ekdromilogo{max-width: 90px;}
.ekdromi-btn{display: block; cursor: pointer;}
.img-fluid.hotel-menu{max-width: 75px;}
.ekdromi-btn p{
  border: 1px solid #b09140;
  color: #fff;
  background-color: #b09140;
  padding:5px;
  line-height: 1;
  font-size: 14px;
  display: block;
  text-align: center;
  margin:0;
  cursor: pointer;
}
/*header social*/
.header-social ul li a{
  color: #fff;
  border: solid 1px #656565;
  font-size: 14px;
  width: 36px;
  height: 36px;
  padding: 0;
  display: block;
  line-height: 35px;
  text-align: center;
  border-radius: 18px;
}
.header-social ul li{margin-right: 10px;}
.header-social ul li a:hover{
  color: #d0b15f;
  border: solid 1px #d0b15f;
}
.header-social ul li a i { line-height: 35px; }
.header-social ul{margin-top: 17px;}
.modal-body p{padding: 10px 10px; margin-bottom: 0;}
.modal-open .modal {
  background-color: rgba(0, 0, 0, 0.70) !important;
}
.terms.news_error p, .terms.news_error p a{ color:red!important; }
.newsletter-form-block .terms p{color: #fff;}
.modal-body.viva-modal h1{    text-align: center; font-size: 34px; margin-bottom: 0px;}
span.viva_code{font-weight: bold; font-size: 38px; }
p.viva-help{text-align: center;}
.modal-body.viva-modal h2{text-align: center;font-size: 19px; margin-bottom: 0px;}

.viva-gold{color:#b09140;}
div#VivaModal{padding-top: 100px;}




/*Newsletter modal*/
#modalNewsletter .modal-header{padding: 0 !important; border: 0 !important;position: relative;}
#modalNewsletter .modal-body{padding: 0 !important; }
.newsletter-head h2{font-size: 44px;text-align: center;font-weight: 800;color: #252525;margin-top: 20px;}
.newsletter-head h3{font-size: 22px;text-align: center;font-weight: 800;color: #252525;padding: 0px 135px;}
.newsletter-head{margin-bottom: 20px;}
.newsletter-list {list-style-type: none; padding: 0px 20px;}
.newsletter-list li{padding: 2px 0px; font-size: 15px;}
.newsletter-list li:before {content: "\f00c"; font-family:FontAwesome; color: #d0b15f;margin-right: 10px;}
#modalNewsletter .modal-dialog{max-width: 880px;  display: inline-block;text-align: left;vertical-align: middle;}
.newsletter-form-box {padding:20px 0px; background: #252525;display: flex;width: 100%;align-self: center;}
.m-email{
  width: 100%;
  display:block;
  background-color: #d2c6a7;
  border: 0;
  height: 45px;
  font-size: 14px;
  color: #4e4e4e;
  text-align: center;
  padding: 0px 20px;
  max-width: 600px;
  margin: 0 auto;
}


.f-form{display: flex;justify-content: center;margin: 0 auto;text-align: center;}
.f-email{width: 100%;max-width: 700px;display: inline-block;background-color: #d2c6a7;border: 0;height: 45px;font-size: 14px;color: #4e4e4e;text-align: right;padding: 0px 20px;}
.submit-newsletter{-webkit-appearance:none; border:0;}
#applyModalNewsletter{display: block;text-align: center;margin: 0 auto;margin-top: 10px; max-width: 600px; border:0; width: 100%;}
.modla-txt{display: block; text-align: center; color: #fff; padding:0px 40px;}
.terms-form-newletter p{padding: 0;font-size: 11px;line-height: 1.5;text-align: center;}
.terms-form-newletter p a{color: #9e8136; font-weight: bold;}
.close.news-modal-close{display: block;margin: 0 auto;text-align: center;float: none;padding: 40px 0px;font-size: 14px;color: #252525;}

#ms-sub-form input::placeholder{color: #252525;}
#ms-sub-form input:focus{outline-color:#9e8136; outline-style: none;}
.terms-form-newletter{margin-top: 20px;}
 .close{padding: 30px 40px !important;}
#modalNewsletter {text-align: center;}
.close-modal{padding: 20px 20px !important; position: absolute;right: 10px;top: 10px;z-index: 22;}
@media screen and (min-width: 768px) {
  #modalNewsletter:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}


.modal-open .modal{background-color: rgba(0, 0, 0, 0.4) !important;}



/*Deals accordion*/
#accordionDeals {width: 100%; margin-top: 20px;}
#accordionDeals  .card{margin-bottom: 15px;background-color: #f5f3ee;border: 1px solid #d7d6cf;;border-radius: 0;border-bottom: 0;}
.card h5 button{
    position: relative;
}

.card h5 button:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute ;
    right: 0;
    top: 2px;
    font-size: 25px;
    color: #b09140;
}
/*.deals-btn-acc.active-accr:after{content: "\f106";}*/
.newsletter_terms{
  padding: 20px 0px;
  background: #252525;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.newsletter_terms input[type="checkbox"] {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  border: solid 1px #d7d6cf;
  line-height: 1;
  margin-right: 7px;
}
.newsletter_terms input[type="checkbox"]:checked:after{
    content: "\f00c";
    font-family: FontAwesome;
    text-align: center;
    line-height: 14px;
    display: block;
    color: #d0b15f;
    font-weight: 100;
    font-size: 10px;
}
.newsletter_terms p a{color: #9e8136;font-weight: 800;}
.newsletter_terms p {color: #fff; padding: 0; font-size: 12px;}

.mobile-category-slider{display: none;}


#newsletter-static-pages h1{font-size: 40px;color: #252525;text-align: center;font-weight: 700;margin-bottom: 10px;margin-top: 20px;}
#newsletter-static-pages{margin-top: 40px; min-height: 400px;}
.nesletter-pages-body p{text-align: center; font-size: 17px;}
.cart-register-btn{text-align: center; margin-top: 100px;}
.cart-register-btn a{background-color: #b09140;color: #fff;padding: 30px 80px;}
.mobile-table-tlt, .mobile-pos-change, .mobile-pos-tlt{display: none;}
#ndeal-menu-banner img{margin:0 auto; display: block; max-width: 100%;}
#ndeal-menu-banner{margin-bottom: 20px;}
#mdeal-menu-banner{display: none;}

.hidden-sub-menu{
  position: absolute;
  left: 240px;
  background-color: #9e8136;
  z-index: 4;
  width: 240px;
  display: none;
  top: 0;
}
.open_menu{
  float: right;
  position: absolute;
  right: 0;
  padding-left: 8px;
  padding-right: 8px;
  top: 0;
  line-height: 42px;
  cursor: pointer;
  color: #fff;
  border-left: solid 1px #d0b15f;
  text-align: center;
}
/*.dp-page-menu ul li{background-color: transparent !important;}*/
/*.submenu-tlt>a>span{display: none;}*/
/*.submenu-tlt>a{width: calc(100% - 33px);}*/
.submenu-tlt:after{
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  padding: 0px 10px;
  font-size: 20px;
  line-height: 42px;
}
.submenu-tlt>a:hover{background-color: #d0b15f;}
.submenu-tlt>a>span{padding-right: 17px; display: block;}
/*.hidden-sub-menu>li>a{background-color: #d0b15f;}*/
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-2px);
  }
}

.open_menu:hover i{
   -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
.category_name {margin-bottom: 40px; text-align: center;}
.category_name h1{
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;

}
.menufixed{
  position: fixed;
  left: 0;
  right: 0;
  max-width: 240px;
  top: -42px;
  z-index:100;
}



.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover{
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}
.cd-top.cd-is-visible{
  visibility: visible;
  opacity: 1;
  z-index: 99;
}
a.cd-top{
  color: #fff;
  font-size: 15px;
  padding: 7px 0px;
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 10px;
  left: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  background: #2f2f2f;
  visibility: hidden;
  opacity: 0;
  border-radius: 20px;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
transition: opacity .3s 0s, visibility 0s .3s;
}

/*header newsletter form*/
.h-form{
  display: flex;
  justify-content: start;
  margin: 0 auto;
  text-align: center;
}
.h-email{
  background-color: #000;
  border: 1px solid #b09140;
  color: #fff;
  width: 100%;
  max-width: 380px;
  padding: 0px 20px;
  font-size: 13px;
}

.h-email::placeholder{color: #fff;}
.h-form input::placeholder{color: #fff !important;}
.h-form  input[type="text"]::placeholder{color: #fff !important;}
.header-form input::placeholder{color: #fff !important;}


.h-terms{
  margin-top: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: start;
}

.h-terms input[type="checkbox"]{-webkit-appearance: none; width: 15px;height: 15px;background-color: #ffffff; border: solid 1px #d7d6cf;line-height: 1; margin-right: 7px;}
.h-terms input[type="checkbox"]:checked:after{
     content: "\f00c";
    font-family: FontAwesome;
    text-align: center;
    line-height: 15px;
    display: block;
    color: #d0b15f;
    font-weight: 100;
    font-size: 14px;
}
.h-terms p{display: inline-block; margin: 0;  font-size: 9px; color: #fff; font-weight: 800;}
.h-terms p a{color: #9e8136; font-weight: 800;}


.header_newsletter-form-block{margin-bottom: 10px; margin-top: 14px;}
.header-contact{display: flex; margin-bottom: 10px;}

/*.contant-h-link {margin-left: 80px;}*/
.contant-h-info p {margin-bottom: 0;color: #9e8136;padding-left: 20px;}
.contant-h-info p a {color: #fff;margin-left: 5px;}

.right-header-info{padding-left: 50px;}
.flex-center-header{margin-left: 80px;}


.post{height: 450px; width: 100%; background-size: cover; background-position: center; position: relative;}
.post-tlt{
  position: absolute;
bottom: 0;
padding: 30px 20px;
background-color: rgba(29,29,27,0.8);
left: 0;
min-height: 170px;
right: 0;
}

.post-tlt h2{
  color: #fff;
  font-size: 24px;
}
.more-btn a{
  border: 1px solid #b09140;
  color: #fff;
  background-color: #b09140;
  text-align: center;
  padding: 4px 22px;
}

.more-btn{
  float: right;
}
.post-wrap {position: relative;overflow: hidden;}
.post-wrap:hover .post {-webkit-transform: scale(1.2, 1.2);
-webkit-transition-duration: 5s;
-webkit-transition-timing-function: ease-out;
-moz-transform: scale(1.2, 1.2);
-moz-transition-duration: 5s;
-moz-transition-timing-function: ease-out;
-ms-transform: scale(1.20, 1.20);
-ms-transition-duration: 5s;
-ms-transition-timing-function: ease-out;
-webkit-animation-fill-mode: backwards;
animation-fill-mode: backwards;
/* -webkit-transform: translateZ(0); */
-webkit-backface-visibility: hidden;
backface-visibility: hidden;}

.blog h1{
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  margin-top: 40px;
  /*margin-bottom: 80px;*/
}
.row.blog-cont{
  margin-top: 0px;
  padding-bottom: 100px;
}



/*Single post*/

.single_post_tlt>h2{
  font-family: hypatia-sans-pro, sans-serif;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 6px;
  color: #1f2625;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 40px;
}
.single_post h1{
  font-size: 28px;
  /*letter-spacing: 5px;*/
  color: #1f2625;
  font-weight: 700;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
}
.post-img img{
  display: block;
  margin: 0 auto;
}
.post-txt{
  margin-top: 55px;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}
.post-date-tag{
  font-size: 16px;
  color: #9e8136;
  display: block;
  margin-bottom: 20px;
}
.post-txt p{
  font-size: 15px;
  line-height: 1.87;
  color: #404545;
}
.post-share{margin-top: 50px;}
.post-share span{

  font-size: 12px;
  line-height: 1.87;
  letter-spacing: 0.9px;
  color: #404545;
  display: block;
  text-align: center;
}
.post-share ul{
  padding: 0;
  list-style-type: none;
  text-align: center;
}
.post-share ul li{padding-top: 15px;}
.post-share ul li a{color: #6d645f;}
.post-txt{padding-bottom: 60px;border-bottom: solid 1px #d4d4d4;}
.more_posts h4{
  font-size: 28px;
  letter-spacing: 5px;
  color: #1f2625;
  margin-top: 80px;
  text-align: center;
  margin-bottom: 80px;
  font-weight: 700;
}

.more-news h3{
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  margin-bottom: 50px;
  margin-top: 50px;
}

.post-wrap{
  margin-top: 35px;
}
.more-news{
  margin-bottom: 100px;
}
.post-txt a{
  color: #9e8136;
}
.post-txt  img{
  display: block;
  max-width: 100%;
  height: auto !important;
}
.modal-header{border-bottom: 0;}
.red-btn-deals{color: #e10d2c !important; font-weight: 600;}
.red-btn-deals:after{color: #e10d2c !important;}
.dropdown-menu.r-dropdown{
  max-height: 400px;
  overflow: hidden;
  max-width: 820px;
  overflow-y: scroll;
}
.area_select {background-image: linear-gradient(45deg, transparent 50%, #4d4d4e 50%), linear-gradient(135deg, #4d4d4e 50%, transparent 50%);
background-position: calc(100% - 13px) calc(1em + 11px), calc(100% - 8px) calc(1em + 11px), calc(100% - 2.5em) 0.5em;
background-size: 4px 5px, 5px 5px, 1px 1.5em;
background-repeat: no-repeat;
color: #505050;
padding: 0px 20px;
transition: 0.3s;
cursor: pointer;
height: 36px;-webkit-appearance: none;border: solid 1px #d7d6cf;height: 60px;font-weight: 600; border-radius: 0; width: 100%;}
.area_select:focus{
  outline: 0;
  border: 1px solid #9e8136;
}
.password_forgot_tlt{
  color: #b09140;
  text-align: right;
  margin-right: 20px;
  display: block;
  font-size: 13px;
  /*padding-top: 10px;*/
  font-weight: 900;
}
.input_style{
  background-color: #f5f3ee;
  border: solid 1px #d7d6cf;
  height: 60px;
  border-radius: 0;
  line-height: 30px;
}

.mobile_p_num{display: none;}
.m-brand-logo{
  display: flex;
  justify-content: center;
  align-items: center;
}